import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './common/styles';
import styled, { useTheme } from "styled-components";

const BackgroundClip = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const Image = styled.img`
        width: 85%;
        height: 400px;
        object-fit: cover;
        position: absolute;
        z-index: -1;
        top:0;
        right:0;
        left:0;
        ${theme.breakpoints.down('sm')} {
            height: 280px;
        }
        ${theme.breakpoints.only('xs')} {
            height: 280px;
            width: 100%;
        }
    `

    const RedGlass = styled(Grid)`
        height: 400px;
        align-items: center;
        justify-content: center;
        ${theme.breakpoints.up('sm')} {
            background-color: #801422D9;
            clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
            backdrop-filter: blur( 8px );
            -webkit-backdrop-filter: blur( 8px );
        };
        ${theme.breakpoints.only('sm')} {
            height: 280px;
        };
        ${theme.breakpoints.only('xs')} {
            height: 280px;
            background-color: #801422D9;
            backdrop-filter: blur( 0px );
            -webkit-backdrop-filter: blur( 0px );
            align-items: center;
            justify-content: center;
        };
    `

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            className={classes.wallpaperContainer}
        >
            <Image src="assets/clientsMoreLikely.png" />
            <RedGlass container item xs={12} sm={6}>
                <Typography className={`${classes.redGlassText}`}>
                    Client are <b>9x more likely to succeed</b> when they’re held accountable
                </Typography>
            </RedGlass>
        </Grid>
    )
}

export default BackgroundClip
