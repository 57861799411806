import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import useStyles from './common/styles';
import styled, { useTheme } from "styled-components";
import TAButton from './common/TAButton';
import { DASHBOARD_SIGNUP_LINK } from '../constants';


const OurMission = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const GlassBox = styled(Grid)`
        border: 5px solid rgba(255,255,255,0.4);
        border-radius: 25px;
        backdrop-filter: blur(25px);
        margin-top: 200px;
        margin-bottom: 50px;
        ${theme.breakpoints.only('sm')} {
            backdrop-filter: blur(5px);
            margin: 80px 0px;
        }
        ${theme.breakpoints.only('xs')} {
            backdrop-filter: blur(5px);
            margin: 40px 0px;
        }
    `

    const BackgroundImage = styled(Grid)`
        & {
            background: rgba(0, 0, 0, .25) url("assets/background_2.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-blend-mode: darken;
        }
        &:after {
            padding-top: 50%;
            display: block;
            content: '';
        }
    `

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
            id="ourMission"
        >
            <BackgroundImage container item xs={12} alignItems="center" justify="center" className={classes.wallpaperContainer}>
                <GlassBox container item xs={11} sm={8} alignItems="center" justify="center">
                    <Box maxWidth="650px" mb={6} mt={5} mx="35px">
                        <Typography className={`${classes.titleTextLg} ${classes.whiteColor}`}>
                            Our <b>Mission</b>
                        </Typography>
                        <Typography className={`${classes.descriptionText} ${classes.whiteColor}`} style={{ fontWeight: '500 !important', margin: '16px 0px 5rem 0px' }}>
                            Our mission is to bring coaches a unique platform experience that saves them time, money, and can help them scale their business, leading to further success.
                        </Typography>
                        <Box>
                            <TAButton variant="contained" height="60px" backgroundColor="white" textColor={theme.palette.primary.main} style={{ paddingLeft: props.isSmallDevice ? 'auto' : 60, paddingRight: props.isSmallDevice ? 'auto' : 60 }} href={DASHBOARD_SIGNUP_LINK}>
                                Click here to start For FREE!
                            </TAButton>
                        </Box>
                    </Box>
                </GlassBox>
            </BackgroundImage>
        </Grid>
    )
}

export default OurMission
