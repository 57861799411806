import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './common/styles';

const commonStyle = { color: '#7F7F7F' };
const BuiltBySection = (props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.sectionContainer} justify="center">
            <Grid item xs={12} md={12} lg={8}>
                <Typography className={`${classes.semiBold} ${classes.titleTextXl}`} >
                    Built by practitioners, <br />for practitioners.
                </Typography>
                <Typography className={classes.descriptionTextSm}  style={{...commonStyle}} >
                    We understand that the last few years have been hard.
                </Typography>
                <Typography className={classes.descriptionTextSm}  style={{...commonStyle}} >
                    That's why we've built <b>Fortis Forma</b> in a way to bring you <b>more value</b> than what is currently available and we've made it for a very <b>affordable price.</b>
                </Typography>
                <Typography className={classes.descriptionTextSm}  style={{...commonStyle}} >
                    We take our roles seriously, because <b>your success is our success.</b>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default BuiltBySection
