import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core'
import React from 'react'
import useStyles from './common/styles';
import useDimensions from "./../utils/useDimensions"

const commonStyle = { color: '#282828' };
const MakeYouEfficient = (props) => {
    const classes = useStyles();
    const screen = useDimensions();
    
    const isUpLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    let imageStyle = {};
    if(isUpLg) {
        imageStyle = { width: screen.width * 0.4, marginLeft: "-6rem" };
    }

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.sectionContainer}
        >
            <Grid item xs={12} sm={6} >
                <img
                    style={imageStyle}
                    alt="software demo"
                    src="/assets/makeEfficient.svg"
                    className={classes.sidePoster}
                />
            </Grid>
            <Grid container item xs={12} sm={6} justify="flex-start" alignItems="center" >
                <Box textAlign="left" pl={{md: 5, lg: 10}} pr={{md: 5, lg: 10}}>
                    <Typography color="primary" className={classes.titleText}>
                        Software to make you more efficient
                    </Typography>
                    <Typography className={classes.descriptionText} style={{...commonStyle}}>
                        Progress and  regress exercise with the click of a button.
                    </Typography>
                    <Typography className={classes.descriptionText} style={{...commonStyle}}>
                        Use adaptable search features to find the exact exercise you’re looking for.
                    </Typography>
                    <Typography className={classes.descriptionText} style={{...commonStyle}}>
                        Easily create and personalize your own exercise.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default MakeYouEfficient
