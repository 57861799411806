import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import styled from "styled-components"
import useStyles from './common/styles';

const Image = styled.img`${({ theme }) => `
    height: 100%;
    width: 100%;
    max-width: 1000px;
    ${theme.breakpoints.down('lg')} {
        max-width: 850px;
    }
    ${theme.breakpoints.down('md')} {
        max-width: 700px;
    }
    margin: 8rem auto 5rem auto;
`}`
const DashboadFeature = (props) => {
    const classes = useStyles();

    return (
        <>
            <Grid container direction="column" className={classes.sectionContainer} style={{ marginTop: props.isSmallDevice ? 120 : 180 }}>
                <Grid item>
                    <Typography className={`${classes.bold} ${classes.titleTextLg}`} style={{marginBottom: '1rem'}}>Powerful Dashboard</Typography>
                </Grid>
                <Grid item>
                    <Typography className={`${classes.introSubTitleText} ${classes.bold}`} style={{color: '#808080'}}>
                        to control everything
                    </Typography>
                </Grid>
                <Image src="assets/dashboard.png"/>
            </Grid>
        </>
    )
}

export default DashboadFeature
