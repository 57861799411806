import { Grid, IconButton, Menu, MenuItem } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import styled, {useTheme} from "styled-components"
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from './styles';
import TAButton from './TAButton';
import { DASHBOARD_LOGIN_LINK, DASHBOARD_SIGNUP_LINK } from '../../constants';

export const NAV_MENUS = {
  FEATURES:  'features',
  PRICING:  'pricing',
  BLOG:  'blogs',
};

const TopBarContainer = styled(Box)`
    width:100%;
    display:flex;
    justify-content:space-between;
    position:absolute;
    top:0px;
    background-color:transparent;
    z-index:2;
    color:white;
    align-content:center;
    alignItems:'center'
`
const FloatingHeader = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [scroll, setScroll] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const id = anchorEl ? 'appbar-menu' : undefined;
  useEffect(() => {


    const onScroll = () => {
      const scrollCheck = window.scrollY > 50
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    }

    // setting the event handler from web API

    document.addEventListener("scroll", onScroll)

    // cleaning up from the web API

    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, [scroll, setScroll])

  const openMenu = (e) => {
    if (e && e.currentTarget) {
      setAnchorEl(e.currentTarget);
    }
  }

  const closeMenu = (e) => setAnchorEl(null);

  const handleNavigation = (link) => props.history.push(link);

  const renderMenuPopover = () => {
    return (
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorPosition={{
          left: 100,
          right: 100,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleNavigation("features")}>Features</MenuItem>
        <MenuItem onClick={() => handleNavigation("pricing")}>Pricing</MenuItem>
        <MenuItem onClick={() => handleNavigation("blogs")}>Blog</MenuItem>
        <MenuItem onClick={() => window.location.replace(DASHBOARD_SIGNUP_LINK)}>Start For Free</MenuItem>
        <MenuItem onClick={() => window.location.replace(DASHBOARD_LOGIN_LINK)}>Login</MenuItem>
      </Menu>

    )
  }

  return (
    <TopBarContainer
      boxShadow={scroll ? 2 : 'none'}
      style={{ position: "fixed", top: 0, backgroundColor: "white", padding: "12px 0px" }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        className={classes.navBarContainer}
      >
        <Grid item container xs={2} justify="flex-start">
          <div className="flexCenterRow" onClick={() => props.history.replace("/")}>
            {
              props.isSmallDevice ? (
                <>
                  <img alt="Logo" src={"/assets/arrow-logo.png"} id="arrowLogo" />
                  <img alt="Logo" src={"/assets/text-logo.png"} id="textLogo" />
                </>
              ) : (
                <img alt="Logo" src={"/assets/FF_logo.png"} style={{ height: 55 }} />
              )
            }
          </div>
        </Grid>

        <Hidden mdUp>
          <IconButton onClick={openMenu} aria-describedby={id} style={{marginRight: -25}}>
            <MenuIcon />
          </IconButton>
          {renderMenuPopover()}
        </Hidden>
        <Hidden smDown>
          <Grid item container xs={10} justify="flex-end" spacing={4}>
          {Object.keys(NAV_MENUS).map((menu, index) => (
            <Grid item key={index}>
              <TAButton style={{textTransform: 'capitalize'}} onClick={() => handleNavigation(NAV_MENUS[menu])} color="primary" textColor={props.page === NAV_MENUS[menu] ? theme.palette.primary.main : "#989898"}>
                {NAV_MENUS[menu]}
              </TAButton>
            </Grid>
          ))}
            <Grid item>
              <TAButton color="primary" variant="contained" width="150px" style={{ marginRight: 16 }}  href={DASHBOARD_SIGNUP_LINK}>Start For Free</TAButton>
              <TAButton color="primary" variant="outlined" width="150px" href={DASHBOARD_LOGIN_LINK}>Login</TAButton>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </TopBarContainer>
  )
}

export default FloatingHeader
