import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core'
import React from 'react'
import useDimensions from '../utils/useDimensions';
import useStyles from './common/styles';

const ManageTemplates = (props) => {
    const classes = useStyles();
    const screen = useDimensions();

    const isUpLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    let imageStyle = {};
    if(isUpLg) {
        imageStyle = { width: screen.width * 0.4, marginLeft: "-6rem" };
    }

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.sectionContainer}
        >
            <Grid item xs={12} sm={7}>
                <img
                    style={imageStyle}
                    alt="software demo"
                    src="/assets/manage_templates.svg"
                    className={classes.sidePoster}
                />
            </Grid>
            <Grid container item xs={12} sm={5} justify="flex-start" alignItems="center">
                <Box textAlign="left" pl={{xs: 0}} pt={{xs: 4, sm:0}}>
                    <Typography color="primary" className={`${classes.titleText} ${classes.semiBold}`}>
                        Easily manage your workout templates.
                    </Typography>
                    <Typography className={classes.descriptionTextSm}>
                        If you have multiple clients on the same workout, design it once, then assign it.
                    </Typography>
                    <Typography className={classes.descriptionTextSm}>
                        Customize the workout as required right in your client's profile.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default ManageTemplates
