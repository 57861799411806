import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import useStyles from './common/styles';
import { autonomyIcon, reminderIcon, easyAccesss, feedbackIcon } from "./../assets/customIcons";

const FEATURE_LIST = [
    {
        id: 1,
        icon: easyAccesss,
        heading: "Easy access",
        description: "Your clients will know exactly what to do and how to do it through our app",
    },
    {
        id: 2,
        icon: autonomyIcon,
        heading: "Autonomy",
        description: "Your clients can use the calendar feature to switch the day or see what they did last workout",
    },
    {
        id: 3,
        icon: feedbackIcon,
        heading: "Get Feedback",
        description: "Your clients can tell you exactly how they respond to an exercise or the whole workout",
    },
    {
        id: 4,
        icon: reminderIcon,
        heading: "Set Reminders",
        description: "Your clients can use the calendar feature to switch the day or see what they did last workout.",
    },
]

const MobileAppFeatures = (props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.sectionContainer} style={{ marginTop: 0, marginBottom: props.isMobileOnly ? "14rem":"20rem" }} justify="center" align="center">
            <Grid item xs={12}>
                <Typography color="primary" className={`${classes.bold} ${classes.titleText}`} align="center">
                    Features of our Mobile App
                </Typography>
            </Grid>
            <Grid item container xs={12} sm={12} md={10} lg={9} spacing={3} style={{marginTop: "4rem"}} justify="center">
                <Grid item container xs={12} sm={6} spacing={3} style={{ height: !props.isMobileOnly ? '95%' : 'inherit' }} justify="center">
                    <Card {...props} data={FEATURE_LIST[0]} />
                    <Card {...props} data={FEATURE_LIST[1]} />
                </Grid>
                <Grid item container xs={12} sm={6} spacing={3} style={{ marginTop: !props.isMobileOnly ? '5%' : '-12px' }} justify="center">
                    <Card {...props} data={FEATURE_LIST[2]} />
                    <Card {...props} data={FEATURE_LIST[3]} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MobileAppFeatures

const Card = ({data}) => {
    const classes = useStyles();
    return (
        <Grid item>
            <Box textAlign="start" py={8} px={7} bgcolor="#EFEFEF" borderRadius={10} className={classes.mobileFeatureCard}
            >
                {data.icon}
                <Typography className={`${classes.featDescriptionText} ${classes.bold}`} dangerouslySetInnerHTML={{ __html: data.heading }} style={{ marginBottom: 20, marginTop: '2rem' }} />
                <Typography className={classes.featDescriptionText} dangerouslySetInnerHTML={{ __html: data.description }} />
            </Box>
        </Grid>
    )
}
