import React, { useImperativeHandle, useState } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export const notifier = React.createRef();

const NotificationProvider = React.forwardRef(
  (props, ref) => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    useImperativeHandle(ref, () => ({ handleClose, setError, setSuccess }))

    const handleClose = () => {
      setSuccess(false);
      setError(false);
    }

    return (
      <>
        <Snackbar open={Boolean(success)} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {success}
          </Alert>
        </Snackbar>
        <Snackbar open={Boolean(error)} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </>

    )
  })

export default NotificationProvider;