import React from 'react'
import { Box,  Grid, Typography } from '@material-ui/core'
import useStyles from './common/styles';


const OurClients = (props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.sectionContainer} style={{overflow:'hidden'}}>
            <Grid item xs={12}>
                <Box width="100%" mb={5} mt={5}>
                    <Typography color="primary" className={`${classes.bold} ${classes.titleText}`} align="center">
                        Our Loyal Clients
                    </Typography>
                </Box>
                <Box width="100%" mb={5} mt={-4}>
                    <img src="assets/clients_group.png" width={props.isMobileOnly ? "130%" : "100%"} style={{maxWidth: 950, margin: props.isMobileOnly  ? "0px -10%" : "0px 0px"}} />
                </Box>
            </Grid>
        </Grid>
    )
}

export default OurClients
