const PRICING_TYPES = {
  MONTHLY: 0,
  YEARLY: 1,
};

const fofRed = "#801422";

const ANDROID_LINK =
  "https://play.google.com/store/apps/details?id=com.fitnessforfreedom.fortisforma";

const APPLE_LINK = "https://apps.apple.com/in/app/fortis-forma/id1474110332";

const WEB_APP_LINK = "https://app.fortisforma.com/#/";

const DASHBOARD_LOGIN_LINK = "https://dashboard.fortisforma.com/#/login";

const DASHBOARD_SIGNUP_LINK = "https://dashboard.fortisforma.com/#/signup";

const CONTACT_EMAIL_LINK = "mailto:jonathan@fortisforma.com";

const TOP_VIDEO_LINK = "https://player.vimeo.com/video/699152220";
const TOP_VIDEO_THUMBNAIL = "https://i.vimeocdn.com/video/1413560387-2b71ec4f2f026c04d193fe72a7f876129a35f4741a9e92a953ff2a86bcb9a19e-d_1920x1080"

const HOW_IT_WORKS_VIDEO_LINK = "https://www.youtube.com/watch?v=gDDHBZtivFQ";

const CLIENT_LOGIN_LINK = "https://app.fortisforma.com/#/login";

const CLIENT_SIGNUP_LINK = "https://app.fortisforma.com/#/signup";

const CLIENT_CHALLENGES_LINK = "https://app.fortisforma.com/#/challenges";

const FITNESS_COACH_LINK = "/";

const CLIENT_LINK = "/findafitnesscoach";

const SOCIAL_MEDIA = {
  YOUTUBE: "https://www.youtube.com/channel/UCIjAQI9Tn880tY8IiiZ5Tww ",
  INSTAGRAM: "https://www.instagram.com/fortisforma/ ",
  LINKEDIN: "https://www.linkedin.com/company/68956124 ",
  FACEBOOK: "https://www.facebook.com/fortisforma",
}

const COLLECTION = {
  BLOGS: "blogs"
}

module.exports = {
  COLLECTION,
  CLIENT_CHALLENGES_LINK,
  CLIENT_LOGIN_LINK,
  CLIENT_SIGNUP_LINK,
  HOW_IT_WORKS_VIDEO_LINK,
  TOP_VIDEO_LINK,
  TOP_VIDEO_THUMBNAIL,
  CONTACT_EMAIL_LINK,
  DASHBOARD_SIGNUP_LINK,
  DASHBOARD_LOGIN_LINK,
  ANDROID_LINK,
  APPLE_LINK,
  WEB_APP_LINK,
  PRICING_TYPES,
  fofRed,
  FITNESS_COACH_LINK,
  CLIENT_LINK,
  SOCIAL_MEDIA
};
