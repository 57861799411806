import React, { useImperativeHandle, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core'
import useStyles from './common/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import styled from 'styled-components';
import { isEmail } from "validator";
import TAButton from './common/TAButton';
import theme from '../theme';
import { notifier } from './common/notificationProvider';

const CloseButton = styled(Button)`${({ theme }) => `
    position: absolute !important;
    right: -25px;
    top: -25px;
    background-color: #800020;
    border-radius: 50%;
    padding: 20px;
`}`;

const Input = styled(TextField)`${({ theme }) => `
    width: 100%;
    margin-bottom: 4rem;
    font-size: 2.4em;
`}`;

const ContactFormModal = React.forwardRef(
    (props, ref) => {
        const classes = useStyles();
        const fullScreen = useMediaQuery(theme => theme.breakpoints.only('xs'));
        const [loading, setLoading] = useState(false);
        const [open, setOpen] = useState(false);
        const [name, setName] = useState('');
        const [selectedTitle, setSelectedTitle] = useState('');
        const [email, setEmail] = useState('');
        const [message, setMessage] = useState('');

        const submitDisabled = (!email || !name || !message);
        
        const [error, setError] = useState({
            name: false,
            email: false,
        })

        useImperativeHandle(ref, () => ({ handleClose, handleOpen, setSelectedTitle }))

        const handleClose = () => {
            setOpen(false);
            setName("");
            setEmail("");
            setMessage("");
            setSelectedTitle("");
            setError({
                name: false,
                email: false,
            });
            setLoading(false);
        }

        const handleOpen = () => {
            setOpen(true);
        }

        const setFormData = () => {
            let formattedEmail = email.trim().toLocaleLowerCase();
            let formData = {};
            formData.name = name;
            formData.email = formattedEmail;
            if(selectedTitle) {
                formData.program = selectedTitle;
            }
            if (message) {
                formData.message = message;
            }
            return formData;
        };

        function checkErrors() {
            let result = false;
            if (!name) {
                setError({ ...error, name: true });
                result = true;
            }
            if (!email || !isEmail(email)) {
                setError({ ...error, email: true });
                result = true;
            }
            if(!result) setError({name: false, email: false})
            return result;
        }

        const onSubmit = async () => {
            const result = checkErrors();
            setLoading(true);
            if (result) {
                setLoading(false);
                return;
            } else {
                const firebase = window.firebase;
                const functions = firebase.functions();
                const trainerEnquiry = functions.httpsCallable("sendTrainerEnquiry");
                const data = setFormData();
                try {
                    await trainerEnquiry(data);
                    setLoading(false);
                    notifier && notifier.current.setSuccess("Thanks for reaching out. We'll get back to you within 24 hours");
                    handleClose();
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                    notifier && notifier.current.setError("Something went wrong! Please try again later.")
                }
            }
        };

        const renderCloseButton = () => (
            <CloseButton color='primary' onClick={handleClose}>
                <CloseRoundedIcon fontSize="medium" className={classes.whiteColor} style={{ marginTop: 20, marginRight: 20 }} />
            </CloseButton>
        )

        const renderForm = () => (
            <Box width="100%" my={4}>
                <Input
                    label="Your Name"
                    value={name}
                    error={error.name}
                    onChange={e => setName(e.target.value)}
                    variant="standard"
                />
                <Input
                    label="Your Email"
                    value={email}
                    error={error.email}
                    onChange={e => setEmail(e.target.value)}
                    variant="standard"
                />
                <Input
                    label="What can we do for you?"
                    value={message}
                    multiline={true}
                    rows="5"
                    onChange={e => setMessage(e.target.value)}
                    variant="standard"
                />
            </Box>
        )

        return (
            <Dialog
                fullScreen={fullScreen}
                fullWidth={false}
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                PaperProps={{ style: { borderRadius: 10 } }}
            >
                <Grid container style={{ overflow: 'hidden', position: 'relative' }}>
                    {renderCloseButton()}
                    <Box width="54%" display={{ xs: 'none', sm: 'none', md: 'block' }} position="relative">
                        <Box bgcolor={theme.palette.primary.main + '40'} width="100%" height="100%" position="absolute" />
                        <img src="assets/contactFormSide.png" width="100%" height="100%" style={{ objectFit: 'cover' }} />
                    </Box>
                    <Box width={{ xs: "100%", sm: "100%", md: "46%" }}>
                        <Box padding={6} pt={8}>
                            <Typography color="primary" style={{ fontSize: '3.5em' }}>Fill in the form below,</Typography>
                            <Typography style={{ color: "#ADADAD", fontSize: '2.6em' }}>We will get back soon</Typography>
                            {renderForm()}
                            <Box width="100%" display="flex" justifyContent="flex-end">
                                <TAButton variant="contained"
                                    onClick={() => onSubmit()}
                                    disabled={submitDisabled || loading}
                                    color="primary">
                                        {loading ? <CircularProgress size={20}/> : "Request a callback"}
                                    </TAButton>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Dialog>
        )
    }
)

export const contactModalRef = React.createRef();

export default ContactFormModal
