import React from 'react'
import Footer from './footer';
import FloatingHeader, { NAV_MENUS } from './common/floatingHeaderNew';
import OurPricing from './ourPricing';
import { useMediaQuery } from '@material-ui/core';


const PricingPage = (props) => {
    const isMobileOnly = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <div className="App" >
        <FloatingHeader page={NAV_MENUS.PRICING} isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props} />
        <OurPricing isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <Footer isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        </div>
    )
}

export default PricingPage
