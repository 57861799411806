import { Box, Grid, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import ReactPlayer from 'react-player/lazy'
import { DASHBOARD_SIGNUP_LINK, TOP_VIDEO_LINK, TOP_VIDEO_THUMBNAIL } from '../constants';
import useStyles from './common/styles';
import TAButton from './common/TAButton';
import theme from '../theme';
import useDimensions from '../utils/useDimensions';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';

const ShadowedBox = styled(Box)`
-webkit-box-shadow: -67px 4px 400px -40px #80252080 !important;
-moz-box-shadow: -67px 4px 400px -40px #80252080 !important;
box-shadow: -67px 4px 400px -40px #80252080 !important;
max-width: 848px !important;
max-height: 477px !important;
`

const StyledVideoFrame = styled(ReactPlayer)`
@media (min-width:768px){
    border-radius:8px !important;
}
`

const HeaderSection = (props) => {
    const classes = useStyles();
    const [scroll, setScroll] = useState(false);
    const screen = useDimensions();

    useEffect(() => {
        const onScroll = () => {
            const scrollCheck = window.scrollY > 50
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        }
        // setting the event handler from web API
        document.addEventListener("scroll", onScroll)
        // cleaning up from the web API
        return () => {
            document.removeEventListener("scroll", onScroll)
        }
    }, [scroll, setScroll])

    const renderVideoPlayer = () => {
        const padding = props.isMobileOnly ? 50 : 70;
        const width = props.isSmallDevice ? (screen.width - padding) : screen.width > 1920 ? "848px" : screen.width * 0.44;
        const height= props.isSmallDevice ? (screen.width - padding) / 1.77 : 
        screen.width > 1920 ? "477px" : screen.width * 0.44 / 1.77;
        return (
            <ShadowedBox width={width} borderRadius={10} mt="40px" marginX="auto" overflow="hidden">
                <StyledVideoFrame config={{
                    vimeo: {
                        playerOptions: {
                            byLine: false,
                            controls: false,
                            title: false,
                            autoplay: true,
                            playsinline: true,
                            responsive: true,
                        }
                    }
                    }}
                    light={TOP_VIDEO_THUMBNAIL}
                    width={width}
                    height={height}
                    controls={true}
                    url={TOP_VIDEO_LINK}
                    playIcon={
                        <Box borderRadius="50%" bgcolor="#FFFFFF40" p={1.5}>
                        <Box borderRadius="50%" bgcolor="#FFFFFF66" p={2}>
                            <PlayArrowRoundedIcon color="primary" style={{fontSize: 42}}/>
                        </Box>
                        </Box>
                    } />
            </ShadowedBox>
        )
    }

    return (
        <>
            <Grid container direction="column" className={classes.sectionContainer} style={{marginTop: props.isSmallDevice ? 120 : 180}}>
                <Grid item>
                    <Typography className={`${classes.bold} ${classes.titleTextLg}`}>Exercise Delivery Made Simple</Typography>
                </Grid>
                {renderVideoPlayer()}
                <Grid item>
                    <Box mt={5} mb={5}>
                        <Typography className={classes.introSubTitleText}>
                            All-in-one Platform to build exercise programs and <br /> manage your clients
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item justify="center">
                    <TAButton color="primary" variant="contained" width={props.isSmallDevice ? 'auto' : '150px'} style={{ marginRight: 8 }} href={DASHBOARD_SIGNUP_LINK}>Start For Free</TAButton>
                    <TAButton backgroungColor="#D5D5D5" 
                    textColor={theme.palette.primary.main}
                    href="features"
                    variant="contained" width={props.isSmallDevice ? 'auto' : '150px'} style={{ marginLeft: 8 }}
                    >Learn More</TAButton>
                </Grid>
            </Grid>
        </>
    )
}

export default HeaderSection
