import React, { useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import useStyles from './common/styles';
import Collapse from '@material-ui/core/Collapse';
import { DASHBOARD_SIGNUP_LINK } from '../constants';

const FAQS_DATA = [
    {
        id: 1,
        question: 'Is Fortis Forma the right fit for me?',
        answer: `If you’re looking for software that helps you build professional exercise programs for your clients, help them stay accountable, and manage those clients, then Fortis Forma is probably the right fit for you. The best way to find out is to <a href=${DASHBOARD_SIGNUP_LINK}>start a free trial</a>.`,
    },
    {
        id: 2,
        question: 'What features does Fortis Forma offer?',
        answer: 'We have a lot of great features that can help you manage and grow your business: Easy program design, client tracking and simple document management systems to name a few. Check out everything we offer on <a href="features">our features page</a>.',
    },
    {
        id: 3,
        question: 'I have some questions before I sign up. Where can I contact you?',
        answer: 'We’d love to help you any way we can. Send us your question(s) <a href="#contact-form">here</a>.',
    },
    {
        id: 4,
        question: 'What is your refund policy?',
        answer: 'If you aren’t satisfied with the platform within the first 4 weeks of using it, just send us a message here letting us know how we’re not meeting your needs or expectations, and we’ll give you a full refund.',
    },
    {
        id: 5,
        question: 'I need some features that aren’t on the current software.',
        answer: 'We’re always looking to update and improve our platform to bring you the best experience possible. Send us a message <a href="#contact-form"> here</a> and we’ll see how we can make it happen, maybe what you want is already in our pipeline to be built.',
    },
    {
        id: 6,
        question: 'How will Fortis Forma work “with” me ?',
        answer: 'Whether you’re just starting out or trying to optimize your existing business, we not only can but want to help you improve your business. It could be as simple as helping set up your client onboarding, building your assessment sheets, or being a sound board for some ideas you have; whatever you need, we’re with you every step of the way. We want you succeed and we’re here to help make that happen.',
    }
]

const FAQs = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const handleExpansion = (item) => {
        setExpanded(expanded === item.id ? false : item.id)
    }

    return (
        <Grid container className={classes.sectionContainer} style={{ marinTop: '20em' }} id="FAQs">
            <Grid item xs={12} sm={12} md={3} lg={4}>
                <Box mb={6} pr={{xs: 3,md: 10, lg: 10}}>
                    <Typography align="left" className={classes.faqText}>
                        The FAQs
                    </Typography>
                    <Typography align="left" className={classes.titleText} style={{ margin: '16px 0px 20px 0px' }}>
                        Help center
                    </Typography>
                    <Typography align="left" className={classes.faqText}>
                        Everything you need to know about Fortis Forma
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={8}>
                {FAQS_DATA.map(faqItem => (
                    <Box key={faqItem.id} width="100%" mb={4} className="noselect">
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography align="left" className={classes.faqText} style={{fontWeight: '500', cursor: "pointer"}} onClick={() => handleExpansion(faqItem)}>
                                {faqItem.question}
                            </Typography>
                            <IconButton style={{ padding: 0, marginLeft: 16, height: 22, width: 22 }} onClick={() => handleExpansion(faqItem)}>
                                <img style={{ height: 22, width: 22}} src={`assets/${expanded === faqItem.id ? 'collapse' : 'expand'}_icon.svg`} alt="expand or collape answer"/>
                            </IconButton>
                        </Box>
                        <Collapse in={expanded === faqItem.id}>
                            <Typography align="left" className={classes.faqText} style={{color: "#808080", marginTop: '8px', paddingRight: 26}} dangerouslySetInnerHTML={{ __html: faqItem.answer }}>
                            </Typography>
                        </Collapse>
                    </Box>
                ))}
            </Grid>
        </Grid>
    )
}

export default FAQs
