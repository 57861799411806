import React from 'react'
import Footer from './footer';
import FloatingHeader, { NAV_MENUS } from './common/floatingHeaderNew';
import { useMediaQuery } from '@material-ui/core';
import BlogList from './blogList';


const BlogPage = (props) => {
    const isMobileOnly = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <div className="App" >
        <FloatingHeader isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} page={NAV_MENUS.BLOG} {...props} />
        <BlogList isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <Footer isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        </div>
    )
}

export default BlogPage
