import React from 'react'
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core'
import styled from "styled-components"
import useStyles from './common/styles';

const FeatIcon = styled.img`${({ theme }) => `
    height: 110px;
    width: 110px;
`}`

const BackImage = styled.img`${({ theme }) => `
    height: auto;
    width: 100%;
`}`

const BestFeatures = (props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.sectionContainer} style={{ marginTop: 0 }}>
            <Grid item xs={12}>
                <Typography color="primary" className={`${classes.bold} ${classes.titleText}`} align="center">
                    The Best Features <br /> We Provide
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ overflow: 'hidden' }}>
                <Section
                    leftAligned={true}
                    icon="assets/feat_1_icon.png"
                    image="assets/feat_1.png"
                    heading="Manage Clients"
                    description="Track your clients' workouts, notes, assessments and progress reports in one place"
                />
                <Section
                    leftAligned={false}
                    icon="assets/feat_2_icon.png"
                    image="assets/feat_2.png"
                    heading="Build Templates"
                    description="Build a workout once, assign it as often as you want, then customize it as required"
                />
                <Section
                    leftAligned={true}
                    icon="assets/feat_3_icon.png"
                    image="assets/feat_3.png"
                    heading="Alternate Exercises"
                    description="Find alternative exercises with the click of a button"
                />
                <Section
                    leftAligned={false}
                    icon="assets/feat_4_icon.png"
                    image="assets/feat_4.png"
                    heading="Add own Exercise"
                    description="Can't find what you're looking
                                for? Add your own exercises
                                and movement patterns in"
                />
            </Grid>
            <Grid container item style={{ overflow: 'hidden', marginTop: '16rem' }} justify="center" alignItems="center">
                <Grid item xs={12} sm={8} lg={6}>
                    <img src="assets/feat_phones.svg" width="100%" height="auto" />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BestFeatures

const Section = (props) => {
    let { leftAligned, icon, image, heading, description } = props;
    let mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));
    let isMd = useMediaQuery(theme => theme.breakpoints.only('md'));
    const classes = useStyles();
    let align = leftAligned ? "flex-start" : "flex-end";
    let justify = leftAligned ? "flex-end" : "flex-start";
    const boxStyle = { zIndex: 0 };
    let innerBoxStyle = { width: "25%" }
    let imageWidth = mobileView ? "100%" : isMd ? "70%" : "60%";
    if (!mobileView) {
        if (leftAligned) {
            boxStyle.borderTopLeftRadius = 15;
            boxStyle.borderBottomLeftRadius = 15;
            boxStyle.marginRight = "-70%";
            innerBoxStyle.paddingLeft = "2.5%"
        } else {
            boxStyle.borderTopRightRadius = 15;
            boxStyle.borderBottomRightRadius = 15;
            boxStyle.marginLeft = "-70%";
            innerBoxStyle.paddingRight = "2.5%"
        }
    } else {
        leftAligned = false;
        boxStyle.marginTop = "2rem";
        boxStyle.marginBottom = "4rem";
        boxStyle.borderRadius = 15;
        align = "center";
        justify = "center";
        boxStyle.maxWidth = "100%";
        innerBoxStyle.width = "75%";
    }
    const flexWrap = mobileView ? "wrap" : "nowrap";

    return (
        <Box mt={8} width="100%" display="flex" flexWrap={flexWrap} alignItems="center" justifyContent={justify}>
            <Box width="100%" bgcolor="#F8F8F8" alignItems={align} display="flex" flexDirection="column" py={{ xs: 4, sm: 4, md: 4, lg: 5 }} style={boxStyle} order={leftAligned ? 1 : 2}>
                <Box style={innerBoxStyle}>
                    <FeatIcon src={icon} />
                </Box>
                <Box my={1} style={innerBoxStyle}>
                    <Typography color="primary" className={classes.featHeadingText}>{heading}</Typography>
                </Box>
                <Box style={innerBoxStyle}>
                    <Typography className={classes.featDescriptionText}>{description}</Typography>
                </Box>
            </Box>
            <Box width={imageWidth} order={leftAligned ? 2 : 1} style={{ zIndex: 1, position:'relative' }}>
                <BackImage src={image} />
                {!leftAligned && <Box width="100px" height="100%" position="absolute" top={0} left={0} bgcolor="white" zIndex={-1}/>}
            </Box>
        </Box>
    )
}

