import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core'
import React from 'react'
import useDimensions from '../utils/useDimensions';
import useStyles from './common/styles';

const DocumentManagement = (props) => {
    const classes = useStyles();
    const screen = useDimensions();

    const isUpLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    let imageStyle = {};
    if(isUpLg) {
        imageStyle = { width: screen.width * 0.4, marginRight: "-10rem" };
    }

    const imageGrid = (
        <Grid item xs={12} sm={7}>
            <img
                style={imageStyle}
                alt="software demo"
                src="/assets/manage_documents.svg"
                className={classes.sidePoster}
            />
        </Grid>
    )

    const textGrid = (
        <Grid container item xs={12} sm={5} justify="flex-start" alignItems="center">
            <Box textAlign="left" pt={{xs: 4, sm: 0}}>
                <Typography color="primary" className={`${classes.titleText} ${classes.semiBold}`}>
                    Save time with our document management system
                </Typography>
                <Typography className={classes.descriptionTextSm}>
                    Automate client onboarding.
                </Typography>
                <Typography className={classes.descriptionTextSm}>
                    Build templates for notes and assessments.
                </Typography>
                <Typography className={classes.descriptionTextSm}>
                    Write on your mobile device as if 25 it's a sheet of paper.
                </Typography>
            </Box>
        </Grid>
    )

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.sectionContainer}
            style={{marginTop: "18rem"}}
        >
            {props.isMobileOnly ? (
                <>
                {imageGrid}
                {textGrid}
                </>
            ) : (
                <>
                {textGrid}
                {imageGrid}
                </>
            )}
        </Grid>
    )
}

export default DocumentManagement
