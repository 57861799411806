import './App.css';
import React from 'react'

import {BrowserRouter as Router,HashRouter, Route, Switch} from "react-router-dom";
import TrainerLandingPageNew from "./components/trainerLandingPageNew";
import FeaturesPage from './components/featuresPage';
import PricingPage from './components/pricingPage';
import BlogPage from './components/blogPage';
import BlogDetailPage from './components/blogDetailPage';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDERID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    appId: process.env.REACT_APP_APP_ID,
};
window.firebase.initializeApp(config);

class App extends React.Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    {/*
          <Route path="/" exact component={withRouter(TrainerLandingPage)} /> 
          <Route path="/findafitnesscoach" component={withRouter(Landing)} />
          */}
                    {/* New Routes */}
                    <Route path="/" exact component={TrainerLandingPageNew}/>
                    <Route path="/features" component={FeaturesPage}/>
                    <Route path="/pricing" component={PricingPage}/>
                    <Route path="/blogs" component={BlogPage}/>
                    <Route path="/:name" component={BlogDetailPage}/>
                </Switch>
            </HashRouter>
        )
    }
}

export default App;
