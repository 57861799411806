import React from 'react'
import DashboadFeature from './dashboadFeature';
import Footer from './footer';
import FloatingHeader, { NAV_MENUS } from './common/floatingHeaderNew';
import BestFeatures from './bestFeatures';
import FAQs from './faqs';
import MobileAppFeatures from './mobileAppFeatures';
import { useMediaQuery } from '@material-ui/core';


const FeaturesPage = (props) => {
    const isMobileOnly = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <div className="App" >
        <FloatingHeader page={NAV_MENUS.FEATURES} isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props} />
        <DashboadFeature isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <BestFeatures isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <MobileAppFeatures isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <FAQs isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <Footer isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        </div>
    )
}

export default FeaturesPage
