import React, { useImperativeHandle, useState } from 'react'
import { Box, Dialog, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import useStyles from './common/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const MoneyBackPolicyModal = React.forwardRef(
    (props, ref) => {
        const classes = useStyles();
        const [open, setOpen] = useState(false);

        useImperativeHandle(ref, () => ({ handleClose, handleOpen }))

        const handleClose = () => {
            setOpen(false);
        }

        const handleOpen = () => {
            setOpen(true);
        }

        return (
            <Dialog
                fullWidth={false}
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                scroll="body"
                PaperProps={{ style: { borderRadius: 10 } }}
            >
                <Grid container>
                    <Box width="100%" display="flex" justifyContent="flex-end" alignItems="flex-end" pr={2}>
                        <IconButton onClick={handleClose} style={{ margin: "2rem" }} size="small">
                            <CloseRoundedIcon style={{ color: "rgba(0,0,0,0.5)" }} />
                        </IconButton>
                    </Box>
                    <Box px={5} pb={6} mt={-3}>
                        <Typography className={classes.privacyPolicyHeadingText}>
                            What is your refund policy?
                        </Typography>
                        <Typography className={classes.privacyPolicyText}>
                            If you aren’t satisfied with the platform within the first 4 weeks of using it, just send us a message here letting us know how we’re not meeting your needs or expectations, and we’ll give you a full refund.
                        </Typography>
                    </Box>
                </Grid>
            </Dialog>
        )
    }
)

export const moneyBackPolicyModalRef = React.createRef();

export default MoneyBackPolicyModal
