import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Grid, Typography,} from "@material-ui/core";
import useStyles from "./common/styles";
import {COLLECTION} from "../constants";
import ViewBlogModal from "./viewBlogModal";
import BlogDetailPage from "./blogDetailPage";
import styled from "styled-components";
// import ReactPlayer from 'react-player'

const BlogList = (props) => {
    const classes = useStyles();
    const [blogsList, setBlogsList] = useState([]);
    const [loading, setLoading] = useState(true);

    const [selectedBlog, setSelectedBlog] = useState({});
    const [blogOpen, setBlogOpen] = useState(false);
    const [selectedBlogImg, setSelectedBlogImg] = useState(false);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const isImgLink = (blog) => {
        if (blog.video) {
            return true;
        } else {
            return false;
        }
    };

    const fetchBlogs = async () => {
        try {
            const result = [];
            setLoading(true);
            const snapshots = await window.firebase
                .firestore()
                .collection(COLLECTION.BLOGS)
                .get();
            await snapshots.forEach(async (snapshot) => {
                if (!snapshot.empty) {
                    let data = await snapshot.data();
                    result.push(data);
                }
            });
            // console.log(document.getElementsByClassName('planCard')[0]);
            setBlogsList([...result]);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

  const handleReadMore = (data) => {
    setSelectedBlog(data);

  let obj=data;
  obj.status=isImgLink(data);
    setBlogOpen(true);
    props.history.push({pathname:`/${data.title}`,state:{obj}})
  };


    const handleBlogClose = (data) => {
        setSelectedBlog({});
        setBlogOpen(false);
    };

    const renderBlogList = () => {
        if (loading) {
            return (
                <Box width="100%" my={20} display="flex" justifyContent="center">
                    <CircularProgress/>
                </Box>
            );
        }
        if (blogsList.length > 0) {
            return blogsList.map((data) => (
                <BlogCard
                    {...props}
                    data={data}
                    isImg={isImgLink(data)}
                    onReadMore={handleReadMore}
                />
            ));
        }
        return (
            <Grid item xs={12}>
                <Box my={20}>
                    <Typography color="textSecondary" variant="h5" align="center">
                        Oops! No blogs found.
                    </Typography>
                </Box>
            </Grid>
        );
    };

  return (
    <Grid container className={classes.sectionContainer} justify="center">
      <Box width="100%" mb={6} mt={5}>
        <Typography className={classes.blogPageTitle} align="left">
          Blogs - This is where we tell our stories
        </Typography>
      </Box>
      <Grid
        container
        item
        xs={12}
        spacing={3}
        style={{
          maxWidth: "calc(100% + 24px)",
          flexBasis: "calc(100% + 24px)",
        }}
      >
        {renderBlogList()}
      </Grid>
      {/* <iframe src="https://player.vimeo.com/video/690017366?h=1bd415a223&color=ffffff&byline=0&portrait=0&badge=0" width="640" height="268" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}
      {/* <BlogDetailPage
      open={blogOpen}
        data={selectedBlog}
        isImg={isImgLink(selectedBlog?.image)}>
      </BlogDetailPage> */}
      {/* <ViewBlogModal
        open={blogOpen}
        data={selectedBlog}
        isImg={isImgLink(selectedBlog?.image)}
        onClose={handleBlogClose}
      /> */}
    </Grid>
  );
};

export default BlogList;

const BlogCard = ({data, onReadMore, isImg}) => {
    const [imgWidth, setImgWidth] = useState("");
    const [imgHeight, setImgHeight] = useState("");

    const classes = useStyles();
    useEffect(() => {
        if (document.getElementsByClassName("planCard")) {
            const width = document.getElementsByClassName("planCard")[0].clientWidth;
            const height = ((width - 64) * 9) / 16;
            setImgWidth(width);
            setImgHeight(height);
        }
    }, []);

    // const [vidurl, setVidurl] = useState("");

    // useEffect(() => {
    //     if(!isImg){
    //         fetchVideoSrc(data.image);
    //     }

    // }, [])

    // const fetchVideoSrc = (iframeVal) => {
    //     console.log("vid src",iframeVal.src);
    //     // setVidurl(iframeVal.src);
    // }

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{display: "flex", flexDirection: "column"}}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                height="100%"
                overflow="hidden"
                bgcolor="white"
                className="planCard"
                textAlign="left"
                boxShadow="none"
            >
                <Box width="100%" class="imgWrap">
                    {
                        !isImg ? (
                            <img
                                src={data.image}
                                width="100%"
                                height="auto"
                                style={{objectFit: "cover"}}
                            />
                        ) : (
                            <div
                                dangerouslySetInnerHTML={{__html: data.video}}
                            />
                        )

                        //     <ReactPlayer
                        //     url={data.image}
                        //     controls={false}
                        //     width={imgWidth}
                        //     height={imgHeight}

                        //   />

                    }
                </Box>
                <Box width="100%" mt={3}>
                    <Typography className={classes.blogTitleText}>
                        {data.title}
                    </Typography>
                </Box>
                <Box
                    width="100%"
                    height="auto"
                    my={3}
                    py={3}
                    borderTop="2px solid #00000040"
                    borderBottom="2px solid #00000040"
                >
                    <Typography className={classes.blogDescriptionText}>
                        {data.description}
                    </Typography>
                </Box>
                <Box width="100%" display="flex" justifyContent="end">
                    <Button
                        size="small"
                        variant="text"
                        onClick={() => onReadMore(data)}
                        style={{
                            color: "#3CA2DC",
                            fontWeight: "700",
                            fontSize: "18px",
                            cursor: "pointer",
                            textTransform: "none",
                        }}
                    >
                        Read more...
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
};
