import React, { useState } from "react";
import { Box, Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import useStyles from "./common/styles";
import styled from "styled-components";
import { DASHBOARD_SIGNUP_LINK, PRICING_TYPES } from "../constants";
import TAButton from "./common/TAButton";
import theme from "../theme";
import { contactModalRef } from "./contactFormModal";

const PRICING_CARDS = [
  {
    title: "Coaching Solution",
    monthly: 0,
    yearly: 0,
    points: [
      "Access to 5000+ exercises",
      "Build custom routines and templates",
      "Easy client management",
      "Create your own personalized exercise database",
      "Clients can view their workout through our app, a straight link, or PDF.",
      "Workout tracking",
      "Ongoing support",
    ],
    buttonText: "START FOR FREE",
  },
  {
    title: "Private Practice Solution",
    monthly: 20,
    yearly: 200,
    points: [
      "<b>All Coaching Solution Features +</b>",
      "Build intake forms and assessments",
      "Customizable note taking",
      "Public profile on our client connection Portal",
    ],
    buttonText: "START FREE 2-WEEK TRIAL",
  },
  {
    title: "Enterprise Solution",
    monthly: 40,
    yearly: 400,
    points: [
      "<b>All Private Practice Features +</b>",
      "Unlimited Coaches on the Platform",
      "Multiple Coaches with Client",
      "Shared exercise client",
    ],
    buttonText: "START FREE 2-WEEK TRIAL",
  },
];

const StyledButton = styled(Button)`
  ${({ theme }) => `
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 10px;
    &.MuiButton-root {
        font-size: 1.8em;
        text-transform: none;
        padding: 10px 16px;
        font-weight: 500;
        ${theme.breakpoints.only("xs")} {
            padding: 7px 10px;
            font-size: 1.4em;
        }
    };
    &.MuiButtonGroup-grouped  {
        min-width: 190px;
        ${theme.breakpoints.only("xs")} {
            min-width: 100px;
        }
    };
    &.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
        margin-left: -2px;
    }
    &.MuiButton-outlinedPrimary {
        border: 2px solid ${theme.palette.primary.main};
    };
`}
`;

const OurPricing = (props) => {
  const classes = useStyles();
  const [billingType, setBillingType] = useState(PRICING_TYPES.MONTHLY);

  const renderPlanButton = () => {
    const isMonthlySelected = billingType === PRICING_TYPES.MONTHLY;

    let monthlyBtnStyle = {};
    let yearlyBtnStyle = {};
    const inactiveBtn = {
      color: "#6B6B6B",
      fontWeight: 400,
    };

    isMonthlySelected
      ? (yearlyBtnStyle = inactiveBtn)
      : (monthlyBtnStyle = inactiveBtn);

    return (
      <ButtonGroup color="primary">
        <StyledButton
          onClick={() => setBillingType(PRICING_TYPES.MONTHLY)}
          style={monthlyBtnStyle}
        >
          Monthly Billing
        </StyledButton>
        <StyledButton
          onClick={() => setBillingType(PRICING_TYPES.YEARLY)}
          style={yearlyBtnStyle}
        >
          Yearly Billing (save 10%)
        </StyledButton>
      </ButtonGroup>
    );
  };

  return (
    <Grid container className={classes.sectionContainer}>
      <Box width="100%" mb={2.5} mt={2}>
        <Typography
          color="primary"
          className={`${classes.bold} ${classes.titleText}`}
          align="center"
        >
          Our Pricing
        </Typography>
      </Box>
      <Box width="100%" mb={{ xs: 5, sm: 8.5 }}>
        {renderPlanButton()}
      </Box>
      <Grid container item xs={12} spacing={3} style={{ margin: "auto" }}>
        {PRICING_CARDS.map((data) => (
          <PlanCard data={data} billingType={billingType} {...props} />
        ))}
      </Grid>
    </Grid>
  );
};

export default OurPricing;

const PlanCard = (props) => {
  const { data, billingType } = props;
  const classes = useStyles();
  const isActive = data.monthly == 0;
  const isFree = data.monthly == 0;
  const isBillingMonthly = billingType === PRICING_TYPES.MONTHLY;

  const background = isActive ? "#801422" : "#FFF";
  const titleBackground = isActive ? "#690F1B" : "#F8F8F8";
  const bottomBorder = `1px solid ${isActive ? "#6D111D" : "#CDCDCD"}`;
  const whatsIncludedStyle = {
    color: isActive ? "#FFFF" : "#D3ADB2",
    fontSize: "2.4em",
  };
  const pointsTextStlye = { color: isActive ? "#FFFF" : "#383838" };
  const activeButtonProps = isActive
    ? { backgroundColor: "white", textColor: theme.palette.primary.main }
    : {};
  const price = isBillingMonthly ? data.monthly : data.yearly;
  const monthlyTextStyle = {
    color: "#A5A5A5",
    display: "flex",
    alignItems: "end",
    lineHeight: 2.2,
    fontSize: "2em",
  };

  const handlePlan = (data) => {
    if (data && data.title) {
      contactModalRef && contactModalRef.current.setSelectedTitle(data.title);
      contactModalRef && contactModalRef.current.handleOpen();
    }
  };

  const handleHref = () => {
    //   http://localhost:3000/#/signup?plan=coaching-solution&interval=month
    // if (data.title === "Coaching Solution") {
    //   return `${process.env.REACT_APP_DOMAIN_URL}/#/signup?plan=coaching-solution&interval=month`;
    // }
    // if (data.title === "Private Practice Solution" && billingType === 0) {
    //   return `${process.env.REACT_APP_DOMAIN_URL}/#/signup?plan=private-practice-solution&interval=month`;
    // }
    // if (data.title === "Enterprise Solution" && billingType === 0) {
    //   return `${process.env.REACT_APP_DOMAIN_URL}/#/signup?plan=enterprise-solution&interval=month`;
    // }
    // if (data.title === "Private Practice Solution" && billingType === 1) {
    //   return `${process.env.REACT_APP_DOMAIN_URL}/#/signup?plan=private-practice-solution&interval=year`;
    // }
    // if (data.title === "Enterprise Solution" && billingType === 1) {
    //   return `${process.env.REACT_APP_DOMAIN_URL}/#/signup?plan=enterprise-solution&interval=year`;
    // }
   
      return `${process.env.REACT_APP_DOMAIN_URL}/#/signup`;
    
   
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgcolor={background}
        borderRadius={10}
        height="100%"
        overflow="hidden"
        className="planCard"
        textAlign="left"
      >
        <Grid
          container
          style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
        >
          <Box bgcolor={titleBackground} padding="2.7rem 4rem">
            <Typography
              className={`${classes.planCardTitle} ${
                isActive ? classes.whiteColor : classes.primaryColor
              }`}
            >
              {data.title}
            </Typography>
          </Box>
          <Box mx={3} pb={1} pt={4} borderBottom={bottomBorder} display="flex">
            <Typography
              className={`noMargin ${classes.titleText} ${
                isActive ? classes.whiteColor : ""
              }`}
              style={{ display: "flex", flexGrow: 1 }}
            >
              {isFree ? "Free" : `$${price}`}
            </Typography>
            {!isFree && (
              <Typography style={monthlyTextStyle}>
                {isBillingMonthly ? "/month" : "/monthly, billed anually"}
              </Typography>
            )}
          </Box>
          <Box mx={3} borderBottom={bottomBorder} py={2.5}>
            <Typography className={classes.bold} style={whatsIncludedStyle}>
              What's Included
            </Typography>
          </Box>
          {data.points.map((point) => (
            <Box mx={3} borderBottom={bottomBorder} py={2}>
              <Typography
                style={pointsTextStlye}
                className={classes.planCardPoints}
                dangerouslySetInnerHTML={{ __html: point }}
              />
            </Box>
          ))}
        </Grid>
        <Grid container>
          <Box width="100%" mx={3} mt={5} mb={2.5}>
            <TAButton
              width="100%"
              color="primary"
              variant="contained"
              height="60px"
              {...activeButtonProps}
              //   onClick={isFree ? null : () => handlePlan(data)}
              href={handleHref()}
            >
              {data.buttonText}
            </TAButton>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};
