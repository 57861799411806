import React from 'react'
import PrimaryHeader from "./primaryHeaderNew";
import MakeYouEfficientSection from "./makeYouEfficient";
import ManageTemplates from "./manageTemplates";
import ManageDocuments from "./documentManagement";
import ClientAccountablity from "./clientAccountablity";
import BackgroundClip from "./backgorundClip";
import OurMissionNew from "./ourMissionNew";
import ReviewsSection from "./reviewsSection";
import BuiltBySection from "./builtBySection";
import NutShellSection from './nutShellSection';
import OurPricing from './ourPricing';
import OurClients from './ourClients';
import FAQs from './faqs';
import Footer from './footer';
import FloatingHeader from './common/floatingHeaderNew';
import { useMediaQuery } from '@material-ui/core';


const TrainerLandingPageNew = (props) => {
    const isMobileOnly = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <div className="App" >
        <FloatingHeader isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props} />
        <PrimaryHeader isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <MakeYouEfficientSection isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <BackgroundClip isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <ClientAccountablity isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <OurMissionNew isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <ManageTemplates isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <ManageDocuments isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <ReviewsSection isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <BuiltBySection isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <NutShellSection isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <OurPricing isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <OurClients isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <FAQs isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        <Footer isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        </div>
    )
}

export default TrainerLandingPageNew
