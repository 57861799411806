import React from 'react'
import { Box, Button, Dialog, Typography, useMediaQuery } from '@material-ui/core'
import useStyles from './common/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import PropTypes from "prop-types";
import styled from 'styled-components';

const CloseButton = styled(Button)`${({ theme }) => `
    border-radius: 50%;
    padding: 20px;
    top: 5px;
    right: 10px;
    position: absolute;
    width: 40px;
    height: 40px;
    min-width: 40px !important;
`}`;

const CloseButtonContainer = styled.div`${({ theme }) => `
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
`}`;
const ViewBlogModal = ({ data, open, onClose, isImg }) => {
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme => theme.breakpoints.only('xs'));
    
    const renderCloseButton = () => (
        <CloseButtonContainer>
            <CloseButton variant='text' onClick={onClose}>
                <CloseRoundedIcon fontSize="medium" style={{ color: "rgba(0,0,0,0.5)" }} />
            </CloseButton>
        </CloseButtonContainer>
    )
    

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            maxWidth="md"
            onClose={onClose}
            PaperProps={{ style: { position: 'relative', borderRadius: 10 } }}
        >
            {renderCloseButton()}
            {!isImg?<img alt={data.title} src={data.image} width="100%" height="auto" style={{ objectFit: 'cover' }} />:<div
                    dangerouslySetInnerHTML={{__html: data.video}}
                    />}
            {/* <img src={data.image} width="100%" height="500px" style={{ objectFit: 'contain', backgroundColor: "#F8F8F8" }} /> */}
            <Box px={5} pt={2} pb={5}>
                <Typography className={classes.titleText}>
                    {data.title}
                </Typography>
                <Typography className={classes.descriptionTextSm}>
                    {data.description}
                </Typography>
            </Box>
        </Dialog>
    )
}

ViewBlogModal.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

ViewBlogModal.defaultProps = {
    data: {},
    open: false,
    onClose: () => { },
};

export default ViewBlogModal
