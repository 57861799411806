import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import useStyles from './common/styles';

const ItemCardComponent = (props) => {
    const {imageSrc, text} = props;
    const classes = useStyles();
    return(
        <Grid item xs={12} sm={4} style={{ display: "flex", flexDirection: "column" }}>
            <Box display="flex" flexDirection="column" alignItems="center" bgcolor="#F8F8F8" borderRadius={15} p={{xs: "2rem", sm: "4rem"}} height="100%" >
                <Box bgcolor="primary.main" borderRadius="50%" height="80px" width="80px" display="flex" alignItems="center" justifyContent="center">
                    <img src={imageSrc} alt="icon" height="40px" width="40px" style={{objectFit:'contain'}} />
                </Box>
                <Typography className={classes.cardsText} dangerouslySetInnerHTML={{ __html: text }}>
                </Typography>
            </Box>
        </Grid>
    )
}
const NutShellSection = (props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.sectionContainer} >
            <Box width="100%" mb={{xs: 2, sm: 5}}>
                <Typography className={`${classes.bold} ${classes.titleText}`} style={{color: '#696969'}} align="center">
                    Fortis Forma in a <span className={classes.primaryColor}>Nutshell</span>
                </Typography>
            </Box>
            <Grid container item xs={12} spacing={3} style={{margin: "auto"}}>
                <ItemCardComponent
                    imageSrc="assets/cheaper_icon.png"
                    text="<b>66%</b> Cheaper than competitor software"
                />
                <ItemCardComponent 
                    imageSrc="assets/time_icon.png"
                    text="Spend <b>1/3</b> of the <b>time</b> you normally would creating exercise programs"
                />
                <ItemCardComponent 
                    imageSrc="assets/satisfaction_icon.png"
                    text="<b>8/10 Client</b> satisfaction rating"
                />
            </Grid>
        </Grid>
    )
}

export default NutShellSection
