import React from 'react'
import { useLocation } from 'react-router-dom';
import { Box, Button, Dialog, Typography, useMediaQuery, Grid } from '@material-ui/core'
import Footer from './footer';
import useStyles from './common/styles';
import FloatingHeader, { NAV_MENUS } from './common/floatingHeaderNew';
import BlogList from './blogList';


const BlogDetailPage = ( props  ) => {
    const location=useLocation();
    const data=location.state.obj;
    const classes = useStyles();
    const isMobileOnly = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <div className="App" >
        <FloatingHeader isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} page={""} {...props} />
        <Grid
      item
     
      style={{ display: "flex", flexDirection: "column", paddingTop:90 }}
    >
             
            {/* <img src={data.image} width="100%" height="500px" style={{ objectFit: 'contain', backgroundColor: "#F8F8F8" }} /> */}
            <Box px={10} pt={2} pb={5} className="blogDetailPage">
                {location.state.obj.status?<div dangerouslySetInnerHTML={{__html:data.video}}/>:<img src={data.image} width="100%" height="auto" style={{ objectFit: 'cover' }} />}
                <Typography className={classes.titleText}>
                    {data.title}
                </Typography>
                <Typography className={classes.descriptionTextSm}>
                    {data.description}
                </Typography>
            </Box>
            </Grid>
        <Footer isMobileOnly={isMobileOnly} isSmallDevice={isSmallDevice} {...props}/>
        </div>
    )
}

export default BlogDetailPage
