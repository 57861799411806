import React, { useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import useStyles from './common/styles';
import styled from "styled-components";
import { Rating } from '@material-ui/lab';

const REVIEWS_LIST = [
    {
        id: 1,
        name: 'Kyle Paterson ',
        profileImage: 'assets/kyle_testimonial.jpg',
        quote: `“I’ve been using the Fortis Forma app for the past 2 years and it’s really helped simplify my exercise prescription for clients. Whether it’s putting together a list of stretches or composing an entire multi-faceted training program, the app has been great in helping provide directives and clarity for clients to exercise on their own with confidence.... I’d highly recommend the Fortis Forma app if you’re working with clients remotely or as added support for your in person clients.”`,
        rating: 5,
    },
]

const SliderContainer = styled(Box)`${({ theme }) =>`
    padding: 10rem 15rem;
    background: white;
    ${theme.breakpoints.down('lg')} {
    position: relative;
        padding: 8rem 10rem;
    }
    ${theme.breakpoints.down('md')} {
    position: relative;
        padding: 6rem 12rem;
    }
    ${theme.breakpoints.down('xs')} {
        padding: 4rem 6rem;
        max-width: calc(100% - 70px);
    }
`}`

const ProfileImage = styled.img`${({ theme }) =>`
    object-fit: cover;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    ${theme.breakpoints.down('lg')} {
    
    ${theme.breakpoints.down('xs')} {
    
    }
`}`

const ContainerGrid = styled(Grid)`${({ theme }) =>`
    margin-top: 40em;
    margin-bottom: -8em;
    background: rgb(248,248,248);
    background: linear-gradient(180deg, rgba(248,248,248,1) 0%, rgba(254,254,254,1) 100%);
    ${theme.breakpoints.down('xs')} {
        margin-top: 28em;
        margin-bottom: -10em;
    }
`}`

const StyledRating = styled(Rating)`
    color: #FF9C28;
    font-size: 3em;
`

const ArrowButton = ({ type, disabled, onClick, isMobileOnly }) => {
    const isLeftButton = type === 'left';
    const btnStyle = { backgroundColor: disabled ? '#FFFF' : '#FFEAEA', position: 'absolute', top: '-2rem', height: 46, width: 46, zIndex: 1 };
    isLeftButton ? btnStyle.left = 0 : btnStyle.right = 0;
    let logoSize = 25;
    if (isMobileOnly) {
        btnStyle.height=30;
        btnStyle.width=30;
        logoSize = 20;
    }
    return (
        <IconButton disableRipple={disabled} onClick={onClick} disabled={disabled} color="primary" aria-label="upload picture" component="span" style={btnStyle}>
            <img src={isLeftButton ? 'assets/arrow_left.svg' : 'assets/arrow_right.svg'} style={{ opacity: disabled ? 0.4 : 1, height:logoSize, width:logoSize }} />
        </IconButton>
    )
}

const ReviewsSection = (props) => {
    const classes = useStyles();
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const disableNextButton = currentItemIndex >= REVIEWS_LIST.length - 1;
    const disablePrevButton = currentItemIndex === 0;

    const handleChange = (type = "next") => {
        if (type === "next") {
            setCurrentItemIndex(currentItemIndex + 1);
        } else {
            setCurrentItemIndex(currentItemIndex - 1);
        }
    }

    return (
        <ContainerGrid
            container
            justify="center"
        >
            <Grid container item justify="center" className={classes.sectionContainer} style={{position: 'relative'}}>
                {
                    REVIEWS_LIST.length > 1 && (
                        <>
                            <ArrowButton isMobileOnly={props.isMobileOnly} type="left" disabled={disablePrevButton} onClick={() => !disablePrevButton ? handleChange('prev') : null} />
                            <ArrowButton isMobileOnly={props.isMobileOnly} type="right" disabled={disableNextButton} onClick={() => !disableNextButton ? handleChange('next') : null} />
                        </>
                    )
                }
                <SliderContainer boxShadow={6} display="flex" borderRadius={15} width="100%" mt="-26em" flexWrap={props.isSmallDevice ? 'wrap' : 'nowrap'} flexDirection="row">
                    <Box pt={3} order={{xs: 2 ,sm: 2,md: 1}} mr={{xs: 0, sm: 0, md: 8}} display="inline-flex" flexGrow={1} justifyContent="flex-start" flexDirection="column">
                        <Typography className={classes.descriptionText} align="left" style={{lineHeight: 1.4}}>
                            {REVIEWS_LIST[currentItemIndex].quote}
                        </Typography>
                        <Typography color="primary" align="left" className={`${classes.descriptionTextSm} fontFamilyRoboto`}>
                            {REVIEWS_LIST[currentItemIndex].name}
                        </Typography>
                        <StyledRating
                            style={{ marginTop: -12 }}
                            value={5}
                            readOnly
                            precision={1}
                        />
                    </Box>
                    <Box display="inline-flex" order={{xs: 1,sm: 1, md:2}} mx="auto">
                        <Box borderRadius="50%" p="1rem" className={classes.reviewerProfileImage}>
                            <ProfileImage src={REVIEWS_LIST[currentItemIndex].profileImage}
                                alt={REVIEWS_LIST[currentItemIndex].name || 'profile image'}
                            />
                        </Box>
                    </Box>
                </SliderContainer>
            </Grid>
        </ContainerGrid>
    )
}

export default ReviewsSection
