import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
  sectionContainer: {
    maxWidth: '1500px',
    paddingLeft: 35,
    paddingRight: 35,
    margin: '12rem auto',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1500px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: "78vw",
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "100vw",
      margin: '8rem auto',
      paddingLeft: 25,
      paddingRight: 25,
    },
  },
  navBarContainer: {
    maxWidth: '1500px',
    paddingLeft: 35,
    paddingRight: 35,
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1500px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: "78vw",
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "100vw",
    },
  },
  wallpaperContainer: {
    maxWidth: '1920px',
    marginRight: 'auto',
    marginLeft: 'auto',
    position: 'relative',
    overflow: 'hidden',
  },
  sidePoster: {
    width: '100%',
    height: 'auto',
    maxHeight: 600,
  },
  introSubTitleText: {
    color: '#777472',
    fontSize: '3.2em',
  },
  titleTextLg: {
    fontSize: '6.2em',
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  clientAccountabilityTitle: {
    marginBottom: 24,
    fontSize: '5.5em',
    fontWeight: '500',
  },
  titleTextXl: {
    fontSize: '7.2em',
    lineHeight: '1.3em',
    marginBottom: '38px',
  },
  titleText: {
    marginBottom: 24,
    fontSize: '4.2em',
    lineHeight: 1.2,
    fontWeight: 'bold',
  },
  descriptionText: {
    marginBottom: 20,
    fontSize: '2.6em',
  },
  descriptionTextSm: {
    marginBottom: 20,
    fontSize: '2.2em',
  },
  redGlassText: {
    color: '#FFFFFF',
    maxWidth: 600,
    textAlign: 'right',
    fontSize: '3.2em',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '70%',
      textAlign: 'center',
    },
  },
  whiteColor: {
    color: '#FFFFFF',
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  bold: {
    fontWeight: 'bold',
  },
  semiBold: {
    fontWeight: 500,
  },
  cardsText: {
    color: '#494949',
    fontSize: '2.4em',
    marginTop: '3rem',
    width: '80%',
  },
  reviewerProfileImage: {
    backgroundColor: '#BF253940',
    overflow: 'hidden',
    height: 250,
    width: 250,
    [theme.breakpoints.down('lg')]: {
      height: 200,
      width: 200,
    },
    [theme.breakpoints.down('md')]: {
      height: 180,
      width: 180,
    },
    [theme.breakpoints.down('sm')]: {
      height: 165,
      width: 165,
    },
  },
  planButtonGroup: {
    borderRadius: 10,
  },
  planCardTitle: {
    fontSize: '2.8em',
  },
  planCardPoints: {
    fontSize: '2.1em',
  },
  faqText: {
    fontSize: '2.3em',
  },
  footerLinks: {
    fontSize: '2.2em',
    color: '#FFFFFF',
    fontFamily: 'ProductSans',
  },
  featHeadingText: {
    fontWeight: '500',
    fontSize: '3.2em',
  },
  featDescriptionText: {
    fontSize: '1.8em',
  },
  privacyPolicyHeadingText: {
    fontWeight: 'bold',
    fontSize: '2.3em',
    marginTop: '1.2em',
    marginBottom: '0.6em',
  },
  privacyPolicyText: {
    fontSize: '1.8em',
    marginBottom: '1em',
  },
  mobileFeatureCard: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
      transition: "all 150ms ease-in",
    },
    '&:hover .featIcons .redColor': {
      fill: '#FFF',
    },
    '&:hover .featIcons .whiteColor': {
      fill: theme.palette.primary.main,
    },
    '&:hover .featIcons .redColorDark': {
      fill: '#D8D8DA',
    }
  },
  blogPageTitle: {
    fontWeight: 'bold',
    fontSize: '3.2em',
  },
  blogTitleText: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    fontWeight: 'bold',
    fontSize: '2.6em',
  },
  blogDescriptionText: {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    fontWeight: 'bold',
    fontSize: '1.8em',
    color: "#808080"
  },
  arrowBtns: {
    marginTop: "25px !important",
    padding: '0px 20px',
    cursor: 'pointer !important',
    pointerEvents: "none"
  }
}));

export default useStyles;