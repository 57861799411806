import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'
import styled, { useTheme } from 'styled-components';
import { DASHBOARD_SIGNUP_LINK } from '../constants';
import useStyles from './common/styles';
import TAButton from './common/TAButton';

const ClientAccountability = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const StyledImage = styled.img`
        margin-right: -180px;
        ${theme.breakpoints.only('lg')} {
            max-height: 500px;
        }
        ${theme.breakpoints.only('xs')} {
            margin-right: -25%;
        }
    `

    const commonStyle = { color: '#616161' };

    const textGrid = (
        <Grid container item xs={12} sm={6} justify="flex-start" alignItems="center">
            <Typography align="left" color="primary" className={classes.clientAccountabilityTitle}>
                Easy Client Accountability
            </Typography>
            <Box textAlign="left" pr={{xs:0, sm: 10, md: 10, lg: 10, xl: 10}}>
                <Typography className={classes.descriptionText} style={{ ...commonStyle }}>
                    The mobile app:
                </Typography>
                <Typography className={classes.descriptionText} style={{ ...commonStyle }}>
                    Your clients will always know what exercises to
                    do and how to perform them safely.
                </Typography>
                <Typography className={classes.descriptionText} style={{ ...commonStyle }}>
                    See what exercises your clients are doing and
                    understand how they felt while doing them.
                </Typography>
                <TAButton color="primary" variant="contained" width="150px" style={{ marginTop: 16 }} href={DASHBOARD_SIGNUP_LINK}>
                    Sign Up Now
                </TAButton>
            </Box>
        </Grid>
    )

    const imageGrid = (
        <Grid item xs={12} sm={6}>
            <StyledImage
                alt="software demo"
                src="/assets/red_phone.svg"
                className={classes.sidePoster}
            />
        </Grid>
    )

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.sectionContainer}
            style={{marginTop: '20rem'}}
        >
            {props.isMobileOnly ? (
                <>
                    {imageGrid}
                    {textGrid}
                </>
            ) : (
                <>
                    {textGrid}
                    {imageGrid}
                </>
            )}
        </Grid>
    )
}

export default ClientAccountability
