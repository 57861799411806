import React, { useEffect } from 'react'
import { Box, Grid, IconButton, Link, Typography } from '@material-ui/core'
import useStyles from './common/styles';
import styled from 'styled-components';
import ContactFormModal, { contactModalRef } from './contactFormModal';
import { SOCIAL_MEDIA } from '../constants';
import { useLocation } from 'react-router-dom';
import PrivacyPolicyModal, { privacyPolicyModalRef } from './privacyPolicyModal';
import MoneyBackPolicyModal, { moneyBackPolicyModalRef } from './moneyBackPolicyModal';

const StyledIconButton = styled(IconButton)`
    margin-left: 2rem;
    padding: 0px;
`

const Footer = (props) => {
    const classes = useStyles();
    const location = useLocation();
    
    useEffect(() => {
        if(location.hash === '#contact-form') {
            contactModalRef && contactModalRef.current.handleOpen();
            props.history.replace(location.pathname);
        }
        if(location.hash === '#our-mission') {
            if(location.pathname !== "/") {
                props.history.push("/");
            }
            setTimeout(() => {
                const el = document.getElementById("ourMission");
                if(el) {
                    el && el.scrollIntoView();
                    props.history.replace("/");
                }
            }, 100);
        }
        if(location.hash === '#privacy-policy') {
            privacyPolicyModalRef && privacyPolicyModalRef.current.handleOpen();
            props.history.replace(location.pathname);
        }
        if(location.hash === '#money-back-policy') {
            moneyBackPolicyModalRef && moneyBackPolicyModalRef.current.handleOpen();
            props.history.replace(location.pathname);
        }
    }, [location])

    const gridStyle = { paddingTop: '5rem' };

    return (
        <>
            <Box bgcolor="#303030">
                <Grid container className={classes.sectionContainer} style={{ marginBottom: 0, paddingBottom: '4rem', paddingTop: '2rem', textAlign: 'left' }}>
                    <Grid item container xs={6} sm={6} md={6} lg={3} style={{ display: 'block' }}>
                        <Grid item xs={12} style={gridStyle}>
                            <Link href="features" className={classes.footerLinks}>
                                Features
                            </Link>
                        </Grid>
                        <Grid item xs={12} style={gridStyle}>
                            <Link href="blogs" className={classes.footerLinks}>
                                Blogs
                            </Link>
                        </Grid>
                        <Grid item xs={12} style={gridStyle}>
                            <Link href="#our-mission" className={classes.footerLinks}>
                                Our Mission
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item container xs={6} sm={6} md={6} lg={3} style={{ display: 'block' }}>
                        <Grid item xs={12} style={gridStyle}>
                            <Link href="#contact-form" className={classes.footerLinks} >
                                Support
                            </Link>
                        </Grid>
                        <Grid item xs={12} style={gridStyle}>
                            <Link href="#privacy-policy" className={classes.footerLinks}>
                                Privacy Policy
                            </Link>
                        </Grid>
                        <Grid item xs={12} style={gridStyle}>
                            <Link href="#money-back-policy" className={classes.footerLinks}>
                                Money back guarantee
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Box display="flex" flexWrap="wrap" width="100%" justifyContent="space-between" alignItems="center" mt={10}>
                            <Box display="inline-flex" justifyContent={props.isMobileOnly ? "center" : "flex-end"} alignItems="center" order={{ xs: 2, sm: 1 }} minWidth={props.isMobileOnly ? '100%' : 'inherit'} mt={{ xs: 5, sm: 0 }}>
                                <Typography className={classes.footerLinks}>
                                    Copyright 2022&nbsp;&nbsp;|&nbsp;&nbsp;Fortis Forma
                                </Typography>
                            </Box>
                            <Box flex={1} display="inline-flex" justifyContent={props.isMobileOnly ? "center" : "flex-end"} alignItems="center" order={{ xs: 1, sm: 2 }}>
                                <StyledIconButton color="primary" href={SOCIAL_MEDIA.FACEBOOK} target="_blank">
                                    <img src="assets/Facebook_white.svg" alt="social-facebook"/>
                                </StyledIconButton>
                                <StyledIconButton color="primary" href={SOCIAL_MEDIA.INSTAGRAM} target="_blank">
                                    <img src="assets/Instagram_white.svg" alt="social-instagram"/>
                                </StyledIconButton>
                                <StyledIconButton color="primary" href={SOCIAL_MEDIA.YOUTUBE} target="_blank">
                                    <img src="assets/Youtube_white.svg" alt="social-youtube"/>
                                </StyledIconButton>
                                <StyledIconButton color="primary" href={SOCIAL_MEDIA.LINKEDIN} target="_blank">
                                    <img src="assets/LinkedIN_white.svg" alt="social-linkedin"/>
                                </StyledIconButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <ContactFormModal ref={contactModalRef} />
            <PrivacyPolicyModal ref={privacyPolicyModalRef} />
            <MoneyBackPolicyModal ref={moneyBackPolicyModalRef} />
        </>
    )
}

export default Footer
